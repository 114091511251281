import DataTable from "react-data-table-component";
import { SyncLoader } from "react-spinners";
import NoDataPrompt from "../NoDataPrompt/NoDataPrompt";
import "./Table.css";

export default function Table({
    tableHeaders,
    headerSelector,
    tableData,
    ActionBtn,
    ViewBtn,
    PendingBtn,
    showLoader,
    withActionData,
    onRowClicked,
    SaleBtn,
    ContractBtn,
    SaleBillingBtn,
    checkBtn,
    CheckBox,
    newTabBtn,
    tableType,
    OngoingPOBadges,
    DoneBtn,
    PaymentBtn,
    InvoiceBtn,
    currentAging,
    aboveNinetyAging,
    oneToThirty,
    thirtyOneToSixty,
    sixtyOneToNinety,
    totalAging,
    branchStatus
}) {
    const columns = tableHeaders.map((header, index) => {
        if (header === "-") {
            return {
                name: "",
                selector: (row) => row[headerSelector[index]],
                button: true,
                cell: ViewBtn,
                width: "5vw",
                reorder: false,
                wordWrap: "break-word",
            };
        } if (header === "/") {
            return {
                name: "",
                selector: (row) => row[headerSelector[index]],
                button: true,
                cell: DoneBtn,
                width: "4vw",
                reorder: false,
                wordWrap: "break-word",
            };
        } else if (header === "+") {
            return {
                name: "",
                selector: (row) => row[headerSelector[index]],
                button: true,
                cell: PaymentBtn,
                width: "7vw",
                reorder: false,
                wordWrap: "break-word",
            };
        } else if (header === "*") {
            return {
                name: "",
                selector: (row) => row[headerSelector[index]],
                button: true,
                cell: CheckBox,
                width: "4vw",
                reorder: false,
                wordWrap: "break-word",
            };
        } else if (header === "PO") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                button: true,
                cell: OngoingPOBadges,
                // width: "5vw",
                reorder: false,
                wordWrap: "break-word",
            };
        } else if (header === ".") {
            return {
                name: "",
                selector: (row) => row[headerSelector[index]],
                button: true,
                cell: checkBtn,
                width: "3vw",
                reorder: false,
                wordWrap: "break-word",
            };
        } else if (header === "PYMT REF NO" || header === "PYMT MODE") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                button: true,
                cell: newTabBtn,
                width: "8%",
                reorder: false,
                wrap: true,
            };
        } else if (header === "ACTIONS" && !withActionData) {
            return {
                name: header,
                button: true,
                cell: ActionBtn,
                width: "7vw",
                reorder: false,
                center: true,
            };
        } else if (header === "   ") {
            return {
                name: header,
                button: true,
                cell: PendingBtn,
                width: "17vw",
                reorder: true,
                wordWrap: "break-word",
            };
        } else if (header === " ") {
            return {
                name: header,
                button: true,
                cell: PendingBtn,
                // width: "4vw",
                reorder: true,
                wordWrap: "break-word",
            };
        } else if (header === "PRODUCT NAME") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                left: true,
                width: "40vw",
                reorder: true,
                wordWrap: "break-word",
            };
        }  else if (header === "SUPPLIER"||header === "PAYEE"||header === "CUSTOMER"||header==="PROJECT") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                left: true,
                width: "10vw",
                reorder: true,
                wordWrap: "break-word",
            };
        } else if (header === "PHONE NUMBER") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                right: true,
                width: "10vw",
                reorder: true,
                wordWrap: "break-word",
            };
        } else if (header === "INV NO."|| header === "CONT. NO.") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                right: true,
                width: "6vw",
                cell: InvoiceBtn,
                reorder: true,
            };
        } else if (
            header.includes("SALES")||
            header.includes("BAL")||
            header.includes("AMT")||
            header.includes("DISCOUNT")||
            header.includes("CONTRACT")||
            header.includes("MONTHLY BILLABLES")
            ) {
            return {
                name: header,
                selector: (row) =>
                    row[headerSelector[index]] === null
                        ? "N/A"
                        : row[headerSelector[index]]?.toLowerCase(),
                right: true,
                width: "7vw",
                cell: SaleBtn,
                reorder: true,
            };
        } else if (header === "CONTRACT") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]].toLowerCase(),
                button: true,
                width: "11vw",
                cell: ContractBtn,
                reorder: true,
            };
        } else if (header === "MONTHLY BILLABLES") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]].toLowerCase(),
                button: true,
                width: "11vw",
                cell: SaleBillingBtn,
                reorder: true,
            };
        } else if (header === "TYPE") {
            return {
                name: header,
                selector: (row) => {
                    const value = row[headerSelector[index]];
                    // Capitalize the first letter of each word
                    return value
                        ? value
                            .split(' ')  // Split by space to separate words
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())  // Capitalize each word
                            .join(' ')  // Join words back together
                        : '';  // Handle empty or undefined values gracefully
                },
                button: true,
                width: "10vw",
                cell: SaleBillingBtn,
                reorder: true,
                style: { textAlign: 'center' },  // Center-align the text
            };
        } else if (header === "TOTAL") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                right: true, // For libraries that support it
                width: "9vw",
                reorder: true,
                wrap: true,
                cell: (row) => <div style={{ textAlign: 'right' }}>{row[headerSelector[index]]}</div>, // Cell-level text alignment
                style: { textAlign: 'right' }, // Enforce cell alignment
                headerStyle: { textAlign: 'right' },
            };
        } else if (header === "PYMT STATS") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]].toLowerCase(),
                button: true,
                width: "6vw",
                cell: SaleBillingBtn,
                reorder: true,
            };
        } else if (header === "AMT PAID") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]].toLowerCase(),
                button: true,
                width: "3vw",
                cell: SaleBillingBtn,
                reorder: true,
            };
        } else if (header === "PREP BY") {
            return {
                name: header,
                selector: (row) => {
                    const value = row[headerSelector[index]];
                    // Capitalize the first letter of each word
                    return value.replace(/\b\w/g, char => char.toUpperCase());
                },
                button: true,
                width: "7vw",
                cell: SaleBillingBtn,
                reorder: true,
            };
        } else if (header === "SE NO.") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]].toLowerCase(),
                button: true,
                width: "6vw",
                cell: SaleBillingBtn,
                reorder: true,
            };
        } else if (header === "BRANCH") {
            return {
                name: header,
                button: true,
                width: "7.5vw",
                cell: SaleBillingBtn,
                reorder: true,
            };
        }else if (header === "INV NO.") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]].toLowerCase(),
                button: true,
                width: "3vw",
                cell: SaleBillingBtn,
                reorder: true,
            };
        } else if (header === "CURRENT") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]]?.toLowerCase(),
                cell: currentAging,
                reorder: true,
                // right: true,
                width:"11vw"
            };
        } else if (header === "31-60 DAYS") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]]?.toLowerCase(),
                button: true,
                width: "15vw",
                cell: thirtyOneToSixty,
                reorder: true,
                right: true,
            };
        } else if (header === "61-90 DAYS") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]]?.toLowerCase(),
                button: true,
                width: "15vw",
                cell: sixtyOneToNinety,
                reorder: true,
                right: true,
            };
        } else if (header === "ABOVE 90 DAYS") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]]?.toLowerCase(),
                button: true,
                width: "15vw",
                cell: aboveNinetyAging,
                reorder: true,
                right: true,
            };
        } else if (header === "BRANCH STATUS") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]].toLowerCase(),
                button: true,
                width: "10vw",
                cell: branchStatus,
                reorder: true,
            };
        } else if (header.includes("FRANCHISEE NAME")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "15vw",
                reorder: true,
            };
        } else if (header.includes("BRANCH NAME")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "16vw",
                reorder: true,
                wrap: true,
            };
        } else if (header === "COMPANY EMAIL") {
            return {
                name: header,
                selector: (row) =>
                    row[headerSelector[index]] === null
                        ? "N/A"
                        : row[headerSelector[index]]?.toLowerCase(),
                left: true,
                width: "15vw",
                reorder: true,
                Cell: (row) => {
                    const email = row.email;
                    if (!email) return "";
                    return email.toLowerCase();
                },
                wrap: true,
            };
        } else if (header.includes("SUPPLIER")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "10vw",
                reorder: true,
                wrap: true,
            };
        } else if (header.includes("DOC TYPE")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "8vw",
                reorder: true,
                wrap: true,
            };
        } else if (header.includes("UNIT") || header.includes("QUANTITY") || header.includes("PRICE")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "8vw",
                reorder: true,
                wrap: true,
            };
        } else if (header.includes("COMPANY NAME")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "15vw",
                reorder: true,
                wrap: true,
            };
        } else if (header.includes("SUPPLIER/VENDOR")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "10vw",
                reorder: true,
                wrap: true,
            };
        } else if (header.includes("PO NO.")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                right: true,
                sortable: true,
                width: "6.8vw",
                reorder: true,
                wrap: true,
            };
        } else if (header.includes("DOC NO.")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                right: false,
                // sortable: true,
                width: "11vw",
                reorder: true,
                wrap: true,
            };
        } else if ((header.includes("NO.") || 
                    header.includes("no.")|| 
                    header === "CUR QTY" || 
                    header === "BRKDN VALUE" || 
                    (header.includes("DR NO"))) && 
                    header !== "INV NO.") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                right: true,
                sortable: true,
                width: "5vw",
                reorder: true,
            };
        } else if (header.includes("ROLE") || header.includes("TYPE")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "12vw",
                reorder: true,
            };
        } else if (
            header.includes("USERNAME")
        ) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                left: true,
                sortable: true,
                width: "30vw",
                reorder: true,
            };
        } else if (
            header.includes("INITIAL CASH IN DRAWER")
        ) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                right: true,
                sortable: true,
                width: "16vw",
                reorder: true,
            };
        } else if (header.includes("FULL NAME")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "25vw",
                reorder: true,
            };
        } 
        // else if (header === "PAYMENT DATE" || header === "PAY DATE") {
        //     return {
        //         name: header,
        //         selector: (row) => row[headerSelector[index]],
        //         left: true,
        //         sortable: true,
        //         width: "14vw",
        //         reorder: true,
        //     };
        // } 
        else if (header === "DETAILS") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "30vw",
                reorder: true,
                wrap: true,
            };
        } else if (header === "MIN" || 
            header === "MAX" || 
            header.includes("QTY") || 
            header.includes("Qty") ||
            header.includes("COUNT") ||
            header.includes("DIFFERENCE")||
            // header.includes("QUANTITY")||
            // header.includes("PRICE")||
            header.includes("YIELD")
            ) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                right: true,
                sortable: true,
                width: "8vw",
                reorder: true,
                wrap: true,
            };
        } else if (header === "ADDRESS") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "30vw",
                reorder: true,
                wrap: true,
            };    
        } 
        // else if (header.includes("DATE ISSUED")) {
        //     return {
        //         name: header,
        //         selector: (row) => row[headerSelector[index]],
        //         left: true,
        //         sortable: true,
        //         width: "10vw",
        //         reorder: true,
        //         wrap: true,
        //     };
        // } 
        else if (header.includes("DATE")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                left: true,
                sortable: true,
                width: "6vw",  
                reorder: true,
                wrap: true,
            };
        } else if (
            // header.includes("TOTAL")|| 
            header.includes("AMOUNT PAID")|| 
            (header.includes("AMOUNT") && header !== "INVOICE AMOUNT")
            ) {
                return {
                    name: header,
                    selector: (row) => row[headerSelector[index]],
                    button: true,
                    width: "10vw",
                    cell: (row) => <div style={{ textAlign: 'right' }}>{row[headerSelector[index]]}</div>, // Ensure cell content aligns right
                    reorder: true,
                    style: { textAlign: 'right' }, // Inline style for cells
                    headerStyle: { textAlign: 'right' },
            };
        } else if (header.includes("STATUS")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "10vw",
                reorder: true,
                wrap: true,
            };
        } else if (header.includes("BY")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "9vw",
                reorder: true,
                wrap: true,
            };
        } else if (header.includes("BALANCE")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "7vw",
                reorder: true,
                wrap: true,
            };
        } else if (header.includes("INVOICE NO")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                left: true,
                width: "5vw",
            };
        } else if (header === "INVOICE AMOUNT") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                width: "6vw",
                right: true,
            };
        } else if (header === "PAID"||header==="RECEIVABLE"||header==="TOTAL PROFIT"||header==="PROJECT EXPENSE") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                width: "10vw",
                right: true,
                sortable: true,
                
            };
        } else {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "10vw",
                reorder: true,
                wrap: true,
            };
        }
    });



    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true,

    };

    const customStyles = {
        rows: {
            style: {
                minHeight: "6.2vh",
            },
        },
    };

      const footer = addFooterRow(tableData, columns);

function addFooterRow(data) {
    const keysArray = Object.keys(Object.assign({}, ...tableData));
    let obj = {};
    for (let key of keysArray) {
      let countLength = 0;
     tableData.map((item) => {
        if (item === "expense_total") {
          countLength = countLength + 1;
        }
        if (typeof item === "number") {
          countLength = countLength + item;
        }
      });
      obj[key] = `Total ${countLength}`;
    }
    return obj;
  }

    return showLoader ? (
        <div className="d-flex justify-content-center my-5">
            <SyncLoader color="#5ac8e1" size={15} />
        </div>
    ) : (
        <div>
        <DataTable
            pagination={tableType === "item_history" ? false : true}
            striped
            fixedHeader
            fixedHeaderScrollHeight="80vh"
            columns={columns}
            // data={tableData}
            // footer={footer}
            data={tableData}
            customStyles={customStyles}
            paginationComponentOptions={paginationComponentOptions}
            noDataComponent={<NoDataPrompt />}
            defaultSortFieldId={tableType === "itemList" && 2}
        />
      </div>
    );
}
