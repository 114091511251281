import React, { useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { DatePicker, Select, Table } from "antd"
import Navbar from "../../Components/Navbar/Navbar"
import { formatAmount, toastStyle } from "../../Helpers/Utils/Common"
import { getFinancialReport } from "../../Helpers/apiCalls/SalesReport/SalesReportApi";
import dayjs from "dayjs"

const { RangePicker } = DatePicker
export default function BalanceSheet() {
  const [inactive, setInactive] = useState(true)
  const [filterConfig, setFilterConfig] = useState({
    date_from: dayjs().startOf("month").format("YYYY-MM-DD"),
    date_to: dayjs().endOf("month").format("YYYY-MM-DD"),
    year: dayjs().year(),
  })
  const [showLoader, setShowLoader] = useState(false)
  const [tableData, setTableData] = useState([])

  async function fetchTableData() {
    setShowLoader(true)
    setTableData([])

    const response = await getFinancialReport(filterConfig);

    if (response.data) {
      const formattedData = response.data.account_types.map((asset) => {
        return {
          ...asset,
          key: asset.name,
          total: formatAmount(asset.total_amount?.toFixed(2)),
          children: asset?.children?.map((type) => {
            return {
              ...type,
              key: `${type.name}${type.id}`,
              total: formatAmount(type.total_amount?.toFixed(2)),
              children: type?.children?.map((subtype) => ({
                ...subtype,
                key: `${subtype.name}${subtype.id}`,
                total: !subtype.children
                  ? formatAmount(
                      subtype.total_amount
                        ? subtype.total_amount?.toFixed(2)
                        : "0.00"
                    )
                  : "",
                children: subtype?.children?.map((sub_subtype) => {
                  return {
                    ...sub_subtype,
                    key:
                      sub_subtype.id !== undefined
                        ? `${sub_subtype.name}_${sub_subtype.id}`
                        : `total_${subtype.name}`,
                    name:
                      sub_subtype.name === "total"
                        ? `TOTAL (${subtype.name})`
                        : sub_subtype.name,
                    total: formatAmount(sub_subtype.total_amount.toFixed(2)),
                  }
                }),
              })),
            }
          }),
        }
      })

      setTableData(formattedData)
    }

    setShowLoader(false)
  }

  const months = [
    "jan", "feb", "mar", "apr", "may", "jun",
    "jul", "aug", "sep", "oct", "nov", "dec"
  ];

  const tableColumns = [
    {
      title: "Account Type",
      key: "name",
      dataIndex: "name",
      align: "left",
      render: (text, record) => {
        const isCustom = text.toLowerCase().includes("total")
        const isParents =
          text === "sales" || text === "expenses" || text === "income/loss"

        const isTotalKey = record.key.toLowerCase().includes("total_")
        return (
          <span
            style={{
              fontWeight: isParents ? "bold" : "normal",
              textTransform: isParents || isCustom ? "uppercase" : "none",
              color: "#5E5E5E",
              textAlign: "left", // Force left alignment
              display: "block",
            }}
          >
            {text}
          </span>
        )
      },
    },
    // Dynamically generate columns for each month
    ...months.map(month => ({
      title: month.charAt(0).toUpperCase() + month.slice(1), // Capitalize the month name
      key: month,
      dataIndex: month,
      align: "right",
      render: (text, record) => {
        const isParents = ["sales", "expenses", "income/loss"].includes(record.name.toLowerCase());
        const isCustom = record.name.toLowerCase().includes("total");
        const amount = parseFloat(text || 0); // Use the month's value directly
        const isIncomeLoss = record.name.toLowerCase() === "income/loss";
        const isNegative = isIncomeLoss && amount < 0;

        // Format the amount with commas
        const formattedAmount = amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        return (
          <span
            style={{
              fontWeight: isParents ? "bold" : "normal",
              color: "#5E5E5E",
              textAlign: "right",
              display: "block",
            }}
          >
            {formattedAmount}
          </span>
        );
      },
    })),
    {
      title: "Total",
      key: "total",
      dataIndex: "total",
      align: "right",
      render: (text, record) => {
        const isParents = ["sales", "expenses", "income/loss"].includes(record.name.toLowerCase());
        const isCustom = record.name.toLowerCase().includes("total");
        const amount = parseFloat(record.total.replace(/,/g, "")); 
        const isIncomeLoss = record.name.toLowerCase() === "income/loss";
        const isNegative = isIncomeLoss && amount < 0;
        // Format the amount with commas
        const formattedAmount = amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        
        return (
          <span
            style={{
              fontWeight: isParents ? "bold" : "normal",
              color: isNegative ? "red" : "#5E5E5E", // Red for negative values
              textAlign: "right", // Force left alignment
              display: "block",
            }}
          >
            {/* {text} */}
            {formattedAmount}
          </span>
        )
      },
    },
  ]

  useEffect(() => {
    fetchTableData()
  }, [filterConfig])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"FINANCIAL STATEMENTS"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* headers */}
        <Row className="mb-1">
          <Col xs={6}>
            <h1 className="page-title"> Income Statement Report </h1>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={10} className="mt-2 d-flex flex-row gap-3">
            {/* <RangePicker
              defaultValue={[
                dayjs(filterConfig.date_from, "YYYY-MM-DD"),
                dayjs(filterConfig.date_to, "YYYY-MM-DD"),
              ]}
              onChange={(e) => {
                if (e) {
                  setFilterConfig((prev) => ({
                    ...prev,
                    date_from: e[0].format("YYYY-MM-DD"),
                    date_to: e[1].format("YYYY-MM-DD"),
                  }))
                } else {
                  setFilterConfig((prev) => ({
                    ...prev,
                    date_from: "",
                    date_to: "",
                  }))
                }
              }}
            /> */}
            <DatePicker
              picker="year" // Set the DatePicker to year mode
              defaultValue={dayjs().year(filterConfig.year)} // Set default value to the current year
              onChange={(date, dateString) => {
                if (date) {
                  setFilterConfig((prev) => ({
                    ...prev,
                    year: date.year(), // Update the year in filterConfig
                  }));
                } else {
                  setFilterConfig((prev) => ({
                    ...prev,
                    year: null, // Clear the year if no date is selected
                  }));
                }
              }}
            />
          </Col>
        </Row>

        <div className="mt-4">
          <div className="below">
            <div className="px-2">
              <Table
                dataSource={tableData}
                columns={tableColumns}
                loading={showLoader}
                pagination={false}
                size={'small'}
              />
            </div>
          </div>
          <div className="mb-2" />
        </div>
      </div>
    </div>
  )
}
